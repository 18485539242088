@font-face{font-family:'aileron';src:url("/fonts/Aileron-Regular-webfont.eot");src:url("/fonts/Aileron-Regular-webfont.eot?#iefix") format("embedded-opentype"),url("/fonts/Aileron-Regular-webfont.woff") format("woff"),url("/fonts/Aileron-Regular-webfont.ttf") format("truetype"),url("/fonts/Aileron-Regular-webfont.svg#aileronheavy") format("svg");font-weight:normal;font-style:normal}@font-face{font-family:'aileron';src:url("/fonts/Aileron-Heavy-webfont.eot");src:url("/fonts/Aileron-Heavy-webfont.eot?#iefix") format("embedded-opentype"),url("/fonts/Aileron-Heavy-webfont.woff") format("woff"),url("/fonts/Aileron-Heavy-webfont.ttf") format("truetype"),url("/fonts/Aileron-Heavy-webfont.svg#aileronheavy") format("svg");font-weight:bold;font-style:normal}html,body{margin:0;font-family:'aileron', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;height:100%}h1,h2,h3,h4,h5{font-weight:bold}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}#root{height:100%}::-webkit-input-placeholder{color:#CCC;opacity:1}:-ms-input-placeholder{color:#CCC;opacity:1}::-ms-input-placeholder{color:#CCC;opacity:1}::placeholder{color:#CCC;opacity:1}:-ms-input-placeholder{color:#CCC}::-ms-input-placeholder{color:#CCC}

.Header_header__JpLRi{display:block;position:fixed;top:0;height:95px;width:100%;background:#FFF;margin:0 5%}@media (max-width: 812px){.Header_header__JpLRi{height:70px}}.Header_header__JpLRi a{color:#542d69}.Header_header__JpLRi a img{display:inline-block;height:50px;width:50px;padding:22.5px 0;float:left}@media (max-width: 812px){.Header_header__JpLRi a img{width:25px;height:25px}}.Header_header__JpLRi a h1{display:inline-block;font-size:24px;line-height:95px;margin:0}@media (max-width: 812px){.Header_header__JpLRi a h1{font-size:12px;line-height:70px}}

.Footer_footer__1W6Zv{position:fixed;bottom:0;width:100%;margin:0 5%}@media (max-width: 812px){.Footer_footer__1W6Zv{display:none}}.Footer_footer__1W6Zv p{font-size:11px}.Footer_footer__1W6Zv p svg{margin-right:5px}

.Input_input__kGFPS{width:100%;border:none;border-bottom:#ccc 3px solid;font-size:2rem;transition:border-bottom-color 300ms ease;padding:15px 0}@media (max-width: 812px){.Input_input__kGFPS{font-size:1rem;border-bottom-width:1px}}.Input_input__kGFPS:active,.Input_input__kGFPS:focus{outline:none;border-bottom-color:#FFF}

.Button_button__3w1dp{margin:20px 0;background:#542d69;color:#FFFFFF;border:none;border-radius:3px;padding:20px;font-weight:bold;font-size:1.5rem;text-transform:uppercase}@media (max-width: 812px){.Button_button__3w1dp{padding:15px;font-size:1rem}}.Button_button__3w1dp:hover{cursor:pointer;background:#68E0BB;color:#000000}.Button_button__3w1dp:active,.Button_button__3w1dp:focus{outline:none}

.Form_form__cOUr4{height:100%;width:100%;position:relative}.Form_form__cOUr4 h2{font-size:3rem}@media (max-width: 812px){.Form_form__cOUr4 h2{font-size:1rem}}.Form_middle__34mwH{width:100%;margin:0;position:absolute;top:50%;transform:translateY(-50%)}.Form_hint__bKi22{margin:20px 0;font-size:1rem;text-align:right;color:#333333}@media (max-width: 812px){.Form_hint__bKi22{display:none}}.Form_actions__24qlc{transition:opacity 300ms ease}.Form_actionsHidden__olW0F{opacity:0}.Form_actionsHidden__olW0F button:hover{cursor:unset}.Form_actionsVisible__z0ovB{opacity:1}.Form_backBtn__3AmGB{position:absolute;top:-3rem;left:0;font-size:1rem;color:#cccccc;height:2rem;line-height:2rem;background:none;border:none}.Form_backBtn__3AmGB span{float:right}.Form_backBtn__3AmGB:active,.Form_backBtn__3AmGB:focus{outline:none}.Form_backBtn__3AmGB:hover{cursor:pointer}

.Submit_submit__kmMz3{height:100%;width:100%;position:relative;text-align:center}.Submit_submit__kmMz3 h2{font-size:3rem}@media (max-width: 812px){.Submit_submit__kmMz3 h2{font-size:1.5rem}}.Submit_submit__kmMz3 p{font-size:1.5rem}@media (max-width: 812px){.Submit_submit__kmMz3 p{font-size:1rem}}.Submit_submit__kmMz3 a{transition:all 300ms ease;text-decoration:none;color:#542d69;border-bottom:2px solid #542d69}.Submit_submit__kmMz3 a:hover{color:#68E0BB;border-bottom:2px solid #68E0BB}.Submit_middle__fdzIS{width:100%;margin:0;position:absolute;top:50%;transform:translateY(-50%)}.Submit_highlight__3uDef{color:#542d69;font-weight:bold}

.App_app__2ziFi{height:100%}.App_view__2EyCo{position:absolute;height:auto;bottom:0;left:0;top:0;right:0;margin:95px 5% 25px 5%}

